<script>

export default {
  data: function () {
    return {
      statusKey: {
        'Waiting Authorization': 'Pending department approval',
        'Rejected-pending Finance Review': 'Rejected — pending ACH admin review',
        'Authorized-Waiting FAMIS': 'Verified and waiting for FAMIS input',
        'Entered into FAMIS': 'Entered into FAMIS',
        'Transferred-Waiting Authorization': 'Transferred — pending new department action',
        'Transferred-Waiting Finance': 'Transferred to ACH admin',
        'Rejected': 'Rejected by ACH admin',
        'Deleted': 'Deleted',
      },
    };
  },
  render(h) {
    return h;
  },
};
</script>
