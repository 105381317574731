<template>
  <div class="container border-div p-b-md">
    <div class="columns">
      <div class="column content section">
        <div class="columns is-centered">
          <div class="column is-two-thirds">
            <nav class="breadcrumb no-left-margin columns" aria-label="breadcrumbs">
              <div class="column is-10">
                <ul class="no-left-margin">
                  <li class="no-top-margin">
                    <router-link
                    :to="`/ach-request/`"
                    class=""
                    >
                      ACH requests
                    </router-link>
                  </li>
                  <li class="no-top-margin is-active"><a href="#" aria-current="page">{{ achId }}</a></li>
                </ul>
              </div>
              <div
                v-if="shouldShowDeleteButton"
                class="column is-2"
              >
                <button
                  class="button is-danger"
                  @click.prevent="openDeleteModal"
                >
                  <!-- :class="{ 'is-loading': submitIsLoading }" -->
                  Delete
                </button>
              </div>
            </nav>

            <h2 class="has-text-centered">
              ACH request details
            </h2>

            <router-view
              :achId="achId"
              :status="status"
              @achApiCallSuccess="getData"
            />

          </div>
        </div>
      </div>

      <modal
        ref="show-delete-button"
        class="is-medium"
        :settings="{ title: 'Delete ACH Request' }"
      >
        <div class="block">
          Are you sure you want to delete this ACH Request?
        </div>

        <ValidationObserver
          v-slot="validation"
          tag="div"
        >
          <input-form :errors="validation.errors">
            <vee-text-area
              v-model="reason"
              label="Reason"
              name="Reason"
              placeholder="Reason"
              rules="required"
            />
            <!-- placeholder="Why are you deleting this request?" -->
            <div class="field is-grouped is-justified-center">
              <div class="control">
                <button
                  class="button is-danger"
                  @click.prevent="deleteRequest(validation)"
                >
                  Yes, delete it
                </button>
              </div>
              <div class="control">
                <button
                  class="button is-primary"
                  @click.prevent="closeDeleteModal"
                >
                  No
                </button>
              </div>
            </div>
          </input-form>
        </ValidationObserver>

      </modal>

    </div>
  </div>
</template>
<script>

import StatusMixin from '@/pages/ach-request/StatusMixin';
import Modal from '@/components/common/Modal';

import {
  TextArea,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import { required } from 'vee-validate/dist/rules';
const VeeTextArea = withValidation(TextArea);

export default {
  name: "AchRequestBase",
  components: {
    Modal,
    VeeTextArea,
  },
  mixins: [ StatusMixin ],
  data() {
    return {
      isLoading: false,
      achId: null,
      department: null,
      departmentId: null,
      status: null,
      bankData: {},
      reason: null,
    };
  },
  computed: {
    shouldShowDeleteButton() {
      let value = false;
      let booleanStatus = this.status && ![ 'Deleted', 'Entered into FAMIS' ].includes(this.status);
      let booleanLogin = this.loggedIn.type === 'AchAdministrator' || this.loggedIn.type.includes('AchAdministrator');
      value = booleanStatus && booleanLogin;
      console.log('shouldShowDeleteButton computed, booleanStatus:', booleanStatus, 'booleanLogin:', booleanLogin, 'value:', value, 'this.status:', this.status);
      return value;
    },
    userDepartmentId() {
      return this.$store.state.users.curUserData.departmentId;
    },
    // routeEnding() {
    //   console.log('routeEnding');
    //   let pathArray = this.$route.fullPath.split('/');
    //   let value = pathArray[pathArray.length - 1];
    //   return value;
    // },
    // title() {
    //   let value;
    //   if (this.routeEnding === 'form') {
    //     value = 'ACH Request Approval Form';
    //   } else if (this.routeEnding === 'pending') {
    //     value = 'ACH Request Pending Department Approval';
    //   } else if (this.routeEnding === 'confirmation' && this.status === 'Authorized-Waiting FAMIS') {
    //     value = 'ACH Request Approved - Pending Finance';
    //   } else if (this.routeEnding === 'confirmation') {
    //     value = 'ACH Request Approved';
    //   } else if (this.routeEnding === 'denied') {
    //     value = 'ACH Request Denied';
    //   } else if (this.routeEnding === 'transfer-other') {
    //     value = 'ACH Request Transfer To Other Department';
    //   } else if (this.routeEnding === 'transfer-finance') {
    //     value = 'ACH Request Transfer To Finance';
    //   }
    //   return value;
    // },
  },
  async fetch({ store }) {
    await store.dispatch('departments/getAllDepartments');
  },
  created() {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
  },
  mounted() {
    this.achId = this.$route.params.id;
    this.getData();
    this.clearBankData();
  },
  methods: {
    async openDeleteModal() {
      this.eventType = 'view';
      this.$refs['show-delete-button'].show();
    },
    closeDeleteModal() {
      this.$refs['show-delete-button'].hide();
    },
    async deleteRequest(validation) {
      console.log('deleteRequest is running');

      const isValid = await validation.validate();
      if (!isValid) {
        return;
      }

      let formData = {
        humanReadableId: this.achId,
        reason: this.reason,
      };
      try {
        const data = await this.$store.dispatch('ach/deleteAchRequest', formData);
        console.log('deleteRequest data:', data);
        this.$router.push('/ach-request');
      } catch (err) {
        return [];
      }
    },
    async getData() {
      console.log('AchRequest.vue, getData is running');
      let query = {
        humanReadableId: this.achId,
      };
      try {
        const { data } = await this.$store.dispatch('ach/getAch', query);
        this.status = data.status;
        if (data.department) {
          this.department = data.department.name;
          this.departmentId = data.department.id;
        }
        // console.log('AchRequest.vue, in getData, data:', data, 'this.status:', this.status, 'this.$route:', this.$route, 'this.userDepartmentId:', this.userDepartmentId);
        if (this.status === 'Waiting Authorization' && [ 'AchAdministrator' ].includes(this.curUserType)) {
          this.$router.push({ name: 'ach-request-pending', params: { 'id': this.achId }});
        } else if (this.status === 'Waiting Authorization') {
          this.$router.push({ name: 'ach-request-form', params: { 'id': this.achId }});
        } else if (this.status === 'Authorized-Waiting FAMIS') {
          this.$router.push({ name: 'ach-request-confirmation', params: { 'id': this.achId }});
        } else if (this.status === 'Entered into FAMIS') {
          this.$router.push({ name: 'ach-request-confirmation', params: { 'id': this.achId }});
        } else if (this.status === 'Rejected') {
          this.$router.push({ name: 'ach-request-denied', params: { 'id': this.achId }});
        } else if (this.status === 'Rejected-pending Finance Review') {
          this.$router.push({ name: 'ach-request-denied', params: { 'id': this.achId }});
        } else if (this.status === 'Transferred-Waiting Authorization' && this.departmentId === this.userDepartmentId) {
          this.$router.push({ name: 'ach-request-form', params: { 'id': this.achId }});
        } else if (this.status === 'Transferred-Waiting Authorization') {
          this.$router.push({ name: 'ach-request-transfer', params: { 'id': this.achId }});
        } else if (this.status === 'Transferred-Waiting Finance') {
          this.$router.push({ name: 'ach-request-transfer', params: { 'id': this.achId }});
        } else if (this.status === 'Deleted') {
          this.$router.push({ name: 'ach-request-deleted', params: { 'id': this.achId }});
        }
      } catch (err) {
        return [];
      }
    },
    async clearBankData() {
      try {
        const bankData = await this.$store.dispatch('ach/clearAchBank');
      } catch (err) {
        return [];
      }
    },
  },
};

</script>

<style lang="scss" scoped>

.breadcrumb {
  margin-top: 1rem;
}

.no-left-margin {
  margin-left: 0px !important;
}

.no-top-margin {
  margin-top: 0px !important;
}

.section {
  padding: 1rem 1.5rem !important;
}

</style>
